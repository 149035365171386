import { Card } from "../components/Card";
import { Art } from "../types/ArtType";
import { useArtData } from "../hooks/useArtData";
import { Spinner } from "../components/Spinner";
import "../static/css/pages/Art.css";
import { Nav } from "../components/Nav";
import { useNavigateToTop } from "../hooks/useNavigationToTop";

export const Print = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  // const filter = (data: any) => {
  //   let filteredArt: Art[] = [];

  //   data.data.map((art: Art) => {
  //     if (art.is_paint === false) filteredArt.push(art);
  //   });

  //   return filteredArt;
  // };

  // const queryKey = ["arts", "print"];
  // const { isLoading, isError, data } = useArtData({ queryKey, filter });

  return (
    <div>
      <Nav />
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: 20 }}>Comming Soon!</p>
      </div>
    </div>
  );

  // return (
  //   <div>
  //     {!(isLoading || isError) ? <Nav /> : null}
  //     {isLoading ? (
  //       <div>
  //         <Nav />
  //         <div
  //           style={{
  //             minHeight: "60vh",
  //             paddingTop: "175px",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Spinner />
  //         </div>
  //       </div>
  //     ) : isError ? (
  //       <div>
  //         <Nav />
  //         <div
  //           style={{
  //             minHeight: "60vh",
  //             paddingTop: "175px",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <h3>Somthing went wrong</h3>
  //         </div>
  //       </div>
  //     ) : data?.length !== 0 ? (
  //       <div className="art-container">
  //         {data?.map((art: Art) => {
  //           return (
  //             <div key={art.id}>
  //               <Card pathname="/print_detail" art={art} />
  //             </div>
  //           );
  //         })}
  //       </div>
  //     ) : (
  //       <div className="cart-container">
  //         <span style={{ fontSize: 20 }}>No print yet!</span>
  //       </div>
  //     )}
  //   </div>
  // );
};
