import { useNavigateToTop } from "../hooks/useNavigationToTop";

export const PrivacyPolicy = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  return (
    <div
      style={{
        padding: 20,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <h1>Privacy Policy</h1>
      <section>
        <p>Last Updated: August 01, 2024</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
      </section>
      <section>
        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p>
              <b>Account</b> means a unique account created for You to access
              our Service or parts of our Service.
            </p>
          </li>
          <li>
            <p>
              <b>Affiliate </b> means an entity that controls, is controlled by
              or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority.
            </p>
          </li>
          <li>
            <p>
              <b>Company </b>(referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to Tsegalidet Paintings.
            </p>
          </li>
          <li>
            <p>
              <b>Cookies </b>are small files that are placed on Your computer,
              mobile device or any other device by a website, containing the
              details of Your browsing history on that website among its many
              uses.
            </p>
          </li>
          <li>
            <p>
              <b>Country </b> refers to: Ethiopia.
            </p>
          </li>
          <li>
            <p>
              <b>Device </b>means any device that can access the Service such as
              a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <b>Personal Data </b> is any information that relates to an
              identified or identifiable individual.
            </p>
          </li>
          <li>
            <p>
              <b>Service </b> refers to the Website.
            </p>
          </li>
          <li>
            <p>
              <b>Service Provider </b> means any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </p>
          </li>
          <li>
            <p>
              <b>Usage Data </b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself.
            </p>
          </li>
          <li>
            <p>
              <b>Website </b> refers to Tsegalidet Paintings, accessible from{" "}
              <a
                style={{ color: "darkblue" }}
                href="https://tsegalidetpaintings.com/"
              >
                https://tsegalidetpaintings.com/
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              <b>You </b> means the individual accessing or using the Service,
              or the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>
        <h4>Personal Data</h4>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Usage Data</li>
        </ul>
        <h4>Tracking Technologies and Cookies</h4>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <p>
              <b>Cookies or Browser Cookies:</b> A cookie is a small file placed
              on Your Device. You can instruct Your browser to refuse all
              Cookies. However, if You do not accept Cookies, You may not be
              able to use some parts of our Service. Unless you have adjusted
              Your browser setting so that it will refuse Cookies, our Service
              may use Cookies.
            </p>
          </li>
          <li>
            <p>
              <b>Local Storage:</b> Stores small amounts of data persistently on
              a user's device. However, if You do not accept, You may not be
              able to use some parts of our Service like using shopping cart.
              Unless you have adjusted Your browser setting so that it will
              refuse Local Storage, our Service may use Local Storage.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h2>Use of Your Personal Data</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>
            <p>
              <b>To contact You: </b> To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </p>
          </li>
          <li>
            <p>
              <b>For the performance of a contract: </b> The development,
              compliance and undertaking of the purchase contract for the
              products, items or services You have purchased or of any other
              contract with Us through the Service.
            </p>
          </li>
        </ul>
        <b>We Will Not Share Your Personal Information</b>
      </section>
      <section>
        <h2>Retention of Your Personal Data</h2>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
      </section>
      <section>
        <h2>Transfer of Your Personal Data</h2>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
      </section>
      <section>
        <h2>Delete Your Personal Data</h2>
        <p>
          You have the right to request that We assist in deleting the Personal
          Data that We have collected about You.
        </p>
        <p>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
      </section>
      <section>
        <h2>Disclosure of Your Personal Data</h2>
        <h3>Law enforcement</h3>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h3>Other legal requirements</h3>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
      </section>
      <section>
        <h2>Security of Your Personal Data</h2>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
      </section>
      <section>
        <h2>Children's Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </p>
        <p>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </p>
      </section>
      <section>
        <h2>Links to Other Websites</h2>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
      </section>
      <section>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </section>
      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>
            <p>
              <b>By visiting this page on our website: </b>
              https://tsegalidetpaintings.com/contact_artist
            </p>
          </li>
          <li>
            <p>
              <b>By using our email: </b>
              tsegalidetpaintings@gmail.com
            </p>
          </li>
        </ul>
      </section>
    </div>
  );
};
