import { useEffect, useState } from "react";
import { CartItem } from "../components/CartItem";
import { useShoppingCart } from "../context/useShoppingCartContext";
import "../static/css/pages/Cart.css";
import { Item } from "../types/ItemType";
import { Form } from "../components/Form";
import { PayPal } from "../components/PayPal";
import { Nav } from "../components/Nav";
import toast from "react-hot-toast";

export const ShoppingCart = () => {
  const [paymentMethod, setPaymentMethod] = useState("contactArtist");

  const [totalPrice, setTotalPrice] = useState(0);

  const [displayForm, setDisplayForm] = useState(false);

  const { cart, clearCart } = useShoppingCart();

  const getTotalPrice = () => {
    let totalPrice = 0;
    cart.map((item) => {
      totalPrice += item.price * item.quantity;
      return totalPrice;
    });
    setTotalPrice(totalPrice);
  };

  useEffect(() => {
    getTotalPrice();
  }, [cart]);

  return (
    <div>
      <Nav />
      {cart.length !== 0 ? (
        <div className="cart-container">
          <h1>Shopping Cart</h1>
          {cart.map((item: Item, index) => {
            return <CartItem key={index} {...item} />;
          })}
          <button
            className="buy-btn"
            style={{
              color: "white",
              backgroundColor: "#FF4433",
              borderColor: "#FF4433",
            }}
            onClick={() => clearCart("Not Sold")}
          >
            Clear Cart
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 30,
                width: 250,
              }}
            >
              <span>Select Payment Method</span>
              <select
                value={paymentMethod}
                className="selector"
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                }}
              >
                <option value={"contactArtist"}>Order</option>
                <option value={"usePayPal"}>Use PayPal</option>
              </select>
              <div style={{ marginTop: 10 }}>
                {paymentMethod === "usePayPal" ? (
                  <span>
                    Regrettably, due to unforeseen circumstances, art purchases
                    using paypal is temporarily unavailable at this time
                  </span>
                ) : (
                  <span>
                    Upon placing your order, we will notify you once the artwork
                    is prepared for delivery.
                  </span>
                )}
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 30 }}
            >
              <span>Total Price: ${totalPrice.toLocaleString()}</span>
              <button
                className="buy-btn"
                onClick={() => {
                  if (paymentMethod === "usePayPal") {
                    toast.error(
                      "Regrettably, due to unforeseen circumstances, art purchases using paypal is temporarily unavailable at this time",
                      { duration: 6000, position: "bottom-center" }
                    );
                    return;
                  }
                  setDisplayForm(!displayForm);
                  setTimeout(() => {
                    window.scrollBy({
                      top: 650,
                      behavior: "smooth",
                    });
                  }, 200);
                }}
              >
                CheckOut
              </button>
            </div>
          </div>
          {displayForm ? (
            <div
              style={{
                margin: 100,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "start",
                width: 400,
                minHeight: "40vh",
              }}
            >
              {paymentMethod === "usePayPal" ? (
                <PayPal amount={totalPrice.toString()} />
              ) : (
                <Form amount={totalPrice} />
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: 20 }}>No items yet!</span>
        </div>
      )}
    </div>
  );
};
