import { useState } from "react";
import "../static/css/components/Form.css";
import emailjs from "emailjs-com";
import { useShoppingCart } from "../context/useShoppingCartContext";
import { Item } from "../types/ItemType";
import { useUpdateStatus } from "../hooks/useUpdateStatus";
import toast from "react-hot-toast";
import { Spinner } from "./Spinner";
import { useSubmitOrder } from "../hooks/useSubmitOrder";

export const Form = ({ amount }: { amount: number }) => {
  const { cart, clearCart } = useShoppingCart();
  const { mutate: updateArt } = useUpdateStatus();
  const { mutate: addOrder } = useSubmitOrder();
  const date = new Date();

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    address: "",
    state: "",
    city: "",
    zipCode: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const [isClicked, setIsClicked] = useState(false);

  const formatOrder = () => {
    var order = "";
    order += `\nOrder Date - ${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    order += `\nOrder Time - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}\n`;

    for (var i = 0; i < cart.length; i++) {
      order += `\nItem ${i + 1}\n`;
      order += `Title - ${cart[i].title}\n`;
      order += `Image - ${cart[i].main_image}\n`;
      order += `${cart[i].is_paint ? "Paint" : "Print"}\n`;
      order += `${cart[i].print_type ? cart[i].print_type + "\n" : null}`;
      order += `Size - ${cart[i].size}\n`;
      order += `Price - $${cart[i].price.toLocaleString()}\n`;
      order += `Quantity - ${cart[i].quantity}\n`;
      order += `Total Item Price - $${(
        cart[i].price * cart[i].quantity
      ).toLocaleString()}\n`;
    }
    order += `\nTotal Order Price - $${amount.toLocaleString()}\n`;
    order += `\nCustomer Information\n`;
    order += `Full Name/Brand Name - ${formData.fullName}\n`;
    order += `Phone Number - ${formData.phoneNumber}\n`;
    order += `Email - ${formData.email}\n`;
    order += `Address - ${formData.address}\n`;
    order += `State - ${formData.state}\n`;
    order += `City - ${formData.city}\n`;
    order += `Zip Code - ${formData.zipCode}\n`;

    addOrder({ order: order });

    return order;
  };

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    setIsClicked(true);
    e.preventDefault();

    try {
      await emailjs.send(
        "service_v0sgbbl",
        "template_ayi8w03",
        {
          to_name: "Tsegalidet",
          from_name: formData.fullName,
          subject: "Order",
          message: formatOrder(),
        },
        "E7v12oPHHDLS_2cj3"
      );
      toast.success("Successfully Ordered", {
        duration: 6000,
      });
      cart.map((item: Item) => {
        if (item.is_paint) {
          updateArt({
            artId: item.id,
            status: "Sold",
          });
        }
      });
      clearCart("Sold");
    } catch (error) {
      toast.error("Something went wrong", {
        duration: 6000,
      });
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <h2 style={{ fontSize: 28 }}>Check Out Form</h2>
      <form onSubmit={handleSubmit} style={{ padding: 20 }}>
        <label htmlFor="fullName" style={{ fontSize: 18 }}>
          Full Name/Brand Name:
        </label>
        <input
          required
          type="text"
          id="fullName"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
        />
        <br />

        <label htmlFor="phoneNumber" style={{ fontSize: 18 }}>
          Phone Number:
        </label>
        <input
          required
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          className="input-field"
        />
        <br />

        <label htmlFor="email" style={{ fontSize: 18 }}>
          Email:
        </label>
        <input
          required
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="input-field"
        />

        <br />
        <label htmlFor="address" style={{ fontSize: 18 }}>
          Address:
        </label>
        <input
          required
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          className="input-field"
        />

        <br />
        <label htmlFor="state" style={{ fontSize: 18 }}>
          State:
        </label>
        <input
          required
          id="state"
          name="state"
          value={formData.state}
          onChange={handleChange}
          className="input-field"
        />

        <br />
        <label htmlFor="city" style={{ fontSize: 18 }}>
          City:
        </label>
        <input
          required
          id="city"
          name="city"
          value={formData.city}
          onChange={handleChange}
          className="input-field"
        />

        <br />
        <label htmlFor="zipCode" style={{ fontSize: 18 }}>
          Zip Code:
        </label>
        <input
          required
          id="zipCode"
          name="zipCode"
          value={formData.zipCode}
          onChange={handleChange}
          className="input-field"
        />

        <br />
        <button disabled={isClicked} type="submit" className="buy-btn">
          Order
        </button>
      </form>
    </div>
  );
};
